











































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'PagesCoreFooter',

  data: () => ({
    links: [
      {
        href: 'https://www.actua-formation.fr/',
        text: 'Actua Formation',
      },
    ],
  }),
})
